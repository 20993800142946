import React from "react";
import { LoadingLabel } from "./ui/loading-label";

export default function FullScreenLoader({ label }: { label?: string }) {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingLabel message={label || "Loading ..."} />
    </div>
  );
}
