// import store from "@/app/store";
import { Database, Tables, TablesInsert } from "@/types";
import { DiscountValueType } from "@/types/koala/KoalaCart";
import { JoinedCart } from "@/types/supabase-custom/JoinedCart";
import { AuthError, PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import { isNumber } from "lodash";
import { err, ok, Result } from "neverthrow";
import { v4 as uuidv4 } from "uuid";
import { ProductStatus } from "../utils/productUtils";

export class SupabaseUtils {
  private supabase: SupabaseClient<Database>;

  constructor(supabase: SupabaseClient<Database>) {
    this.supabase = supabase;
  }

  /** Tenants */
  async updateTenant<Table = Tables<"tenants">>(tenantId: string, updatedFields: object) {
    const { data, error } = await this.supabase
      .from("tenants")
      .update(updatedFields)
      .eq("id", tenantId)
      .select("*")
      .returns<Table[]>()
      .single();
    if (error) {
      return err(error);
    }
    return ok(data);
  }

  /** Configurations */
  async getConfigurations<Table = Tables<"configurations">>({
    fields = "*",
    count = "estimated",
    uuid,
    tenantId = "",
  }: {
    tenantId?: string;
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    uuid?: string;
  } = {}) {
    let query = this.supabase.from("configurations").select(fields, { count }).eq("tenant_id", tenantId);
    if (uuid) {
      query.eq("uuid", uuid);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      // store.getActions().setError(result.error);
      return err(result.error);
    }

    return ok(result.data);
  }

  async getConfigurationByUUID<Table = Tables<"configurations">>(
    // (uuid: string, fields = "*", tenantId: string)
    {
      fields = "*",
      uuid = "",
      tenantId = "",
    }: {
      fields?: string;
      uuid?: string;
      tenantId?: string;
    } = {}
  ) {
    const result = await this.supabase
      .from("configurations")
      .select(fields)
      .eq("uuid", uuid)
      .eq("tenant_id", tenantId)
      .returns<Table[]>()
      .single();

    if (result.error) {
      // store.getActions().setError(result.error);
      return err(result.error);
    }

    return ok(result.data);
  }

  async createConfiguration<Table = Tables<"configurations">>(body: TablesInsert<"configurations">, fields = "*") {
    const { data, error } = await this.supabase
      .from("configurations")
      .insert(body)
      .select(fields)
      .returns<Table[]>()
      .single();

    if (error) {
      return err(error);
    }

    return ok(data);
  }

  async updateConfiguration<Table = Tables<"configurations">>(
    id: number,
    updatedFields: object,
    fields = "*",
    tenantId: string
  ) {
    const { data, error } = await this.supabase
      .from("configurations")
      .update(updatedFields)
      .eq("id", id)
      .eq("tenant_id", tenantId)
      .select(fields)
      .returns<Table[]>()
      .single();
    if (error) {
      return err(error);
    }
    return ok(data);
  }

  // Images
  async uploadImage(bucket: string, image: File) {
    const imageName = `${uuidv4()}_${image.name}`;
    const result = await this.supabase.storage.from(bucket).upload(imageName, image);

    if (result.error) {
      return err(result.error);
    }
    return ok(result.data);
  }

  /** Products */
  async createProduct<Table = Tables<"products">>(body: TablesInsert<"products">, fields = "*") {
    const result = await this.supabase.from("products").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      console.log("result", result);
      return err(result.error);
    }
    return ok(result.data[0]);
  }

  async updateProduct(
    productId: number,
    updatedFields: TablesInsert<"products">
  ): Promise<Result<Tables<"products">, PostgrestError>> {
    const { data, error } = await this.supabase.from("products").update(updatedFields).eq("id", productId).select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  async getProducts<Table = Tables<"products">>({
    fields = "*",
    count = "estimated",
    handle,
    status,
    tenantId = "",
    query = "",
    start,
    end,
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    handle?: string;
    status?: ProductStatus;
    tenantId?: string;
    query?: string;
    start?: number;
    end?: number;
  } = {}): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("products")
      .select(fields, { count })
      .eq("tenant_id", tenantId)
      .is("deleted_at", null)
      .order("created_at", { ascending: false });

    if (handle) {
      searchQuery.eq("handle", handle);
    }

    if (status) {
      searchQuery.eq("status", status);
    } /*else {
      searchQuery.eq("status", ProductStatus.enabled);
    }*/

    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    if (query.trim() != "") {
      searchQuery.or(`name.ilike.%${query}%`);
    }

    const searchResult = await searchQuery;

    if (searchResult.error) {
      return err(searchResult.error);
    }

    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  async getProductByHandle<Table = Tables<"products">>(handle: string, fields = "*", tenantId = "") {
    const result = await this.supabase
      .from("products")
      .select(fields)
      .eq("handle", handle)
      .eq("tenant_id", tenantId)
      .returns<Table[]>()
      .single();

    if (result.error) {
      // store.getActions().setError(result.error);
      return err(result.error);
    }

    return ok(result.data);
  }

  async getProductById<Table = Tables<"products">>(id: number, fields = "*", tenantId = "") {
    const result = await this.supabase
      .from("products")
      .select(fields)
      .eq("id", id)
      .eq("tenant_id", tenantId)
      .returns<Table[]>()
      .single();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async getProductByUuid<Table = Tables<"products">>(uuid: string, fields = "*", tenantId = "") {
    const result = await this.supabase
      .from("products")
      .select(fields)
      .eq("uuid", uuid)
      .eq("tenant_id", tenantId)
      .returns<Table[]>()
      .single();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  /** Cart */
  async getCarts<Table = Tables<"carts">>({
    fields = "*",
    count = "estimated",
    id,
    uuid,
    tenantId = "",
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    uuid?: string;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("carts").select(fields, { count }).eq("tenant_id", tenantId);

    if (id) {
      query.eq("id", id);
    }

    if (uuid) {
      query.eq("uuid", uuid);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async createCart<Table = Tables<"carts">>(body: TablesInsert<"carts">, fields = "*") {
    const result = await this.supabase.from("carts").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data[0]);
  }

  async updateCart<Table = Tables<"carts">>(id: number, updatedFields: object, fields = "*", tenantId: string) {
    const { data, error } = await this.supabase
      .from("carts")
      .update(updatedFields)
      .eq("id", id)
      .eq("tenant_id", tenantId)
      .select(fields)
      .returns<Table[]>();

    if (error) {
      return err(error);
    }

    return ok(data[0]);
  }

  /** Line Items */
  async createLineItem<Table = Tables<"line_items">>(body: TablesInsert<"line_items">) {
    const lineItemResult = await this.supabase.from("line_items").insert(body).select();

    if (lineItemResult.error) {
      return err(lineItemResult.error);
    }
    return ok(lineItemResult.data);
  }

  async updateLineItem(
    id: number,
    updatedFields: object,
    tenantId: string
  ): Promise<Result<Tables<"line_items">, PostgrestError>> {
    const { data, error } = await this.supabase
      .from("line_items")
      .update(updatedFields)
      .eq("id", id)
      .eq("tenant_id", tenantId)
      .select("*");

    if (error) {
      return err(error);
    }

    return ok(data[0]);
  }

  async deleteLineItem(lineItemId: number, tenantId: string) {
    const lineItemResult = await this.supabase
      .from("line_items")
      .delete()
      .eq("id", lineItemId)
      .eq("tenant_id", tenantId);

    if (lineItemResult.error) {
      return err(lineItemResult.error);
    }
    return ok(lineItemResult.data);
  }

  /** Profiles */

  async updateTenantProfileRoles<Table = Tables<"profiles_tenants">>(
    profileId: string,
    tenantId: string,
    roles: string[]
  ) {
    const { data, error } = await this.supabase
      .from("profiles_tenants")
      .update({ roles })
      .eq("id", profileId)
      .eq("tenant_id", tenantId)
      .select("*")
      .returns<Table[]>()
      .single();

    if (error) {
      return err(error);
    }

    return ok(data);
  }

  async createOrUpdateProfile<Table = Tables<"profiles">>(
    body: TablesInsert<"profiles">,
    tenant_id: string,
    fields = "*"
  ): Promise<Result<Tables<"profiles">, PostgrestError | AuthError | null>> {
    console.log("body", body);
    // 1. Search the profiles table by email
    const existingProfileResult = await this.supabase
      .from("profiles")
      .select("*")
      .eq("email", body.email as string);
    // .returns<Table[]>();

    if (existingProfileResult.error) {
      console.log("Error fetching profile:", existingProfileResult.error);
      return err(existingProfileResult.error);
    }

    // If the profile is not found
    if (!existingProfileResult.data || existingProfileResult.data.length === 0) {
      // 2. Create a new profile
      const insertProfileResult = await this.supabase.from("profiles").insert(body).select("*").single();

      if (insertProfileResult.error) {
        console.log("Error inserting profile:", insertProfileResult.error);
        return err(insertProfileResult.error);
      }

      const newProfile = insertProfileResult.data;
      console.log("New profile created:", newProfile);

      // Then create a row in profiles_tenants with profile_id and tenant_id
      const insertPTResult = await this.supabase
        .from("profiles_tenants")
        .insert({ profile_id: newProfile.id, tenant_id })
        .select()
        .single();

      if (insertPTResult.error) {
        console.log("Error inserting into profiles_tenants:", insertPTResult.error);
        return err(insertPTResult.error);
      }

      console.log("Inserted into profiles_tenants:", insertPTResult.data);
      return ok(newProfile); // Return the newly created profile
    }

    // The profile exists
    const existingProfile = existingProfileResult.data[0];
    console.log("Existing profile found:", existingProfile);

    // 3. Check the profiles_tenants table for a row with profile_id and tenant_id
    const existingPTResult = await this.supabase
      .from("profiles_tenants")
      .select("*")
      .eq("profile_id", existingProfile.id)
      .eq("tenant_id", tenant_id)
      .returns<any[]>();

    if (existingPTResult.error) {
      console.log("Error fetching profiles_tenants:", existingPTResult.error);
      return err(existingPTResult.error);
    }

    // Ensure existingPTResult.data is defined before accessing its length
    const ptEntryExists = Array.isArray(existingPTResult.data) && existingPTResult.data.length > 0;

    if (ptEntryExists) {
      console.log("profiles_tenants entry exists:", existingPTResult.data[0]);

      // 3a. Update the profile with the new payload
      const updateProfileResult = await this.supabase
        .from("profiles")
        .update(body)
        .eq("id", existingProfile.id)
        .select()
        .single();

      if (updateProfileResult.error) {
        console.log("Error updating profile:", updateProfileResult.error);
        return err(updateProfileResult.error);
      }

      console.log("Profile updated:", updateProfileResult.data);
      return ok(updateProfileResult.data); // Return updated profile
    } else {
      // 4. profiles_tenants entry does not exist
      console.log("profiles_tenants entry does not exist. Creating one.");

      // Insert into profiles_tenants
      const insertPTResult = await this.supabase
        .from("profiles_tenants")
        .insert({ profile_id: existingProfile.id, tenant_id })
        .select()
        .single();

      if (insertPTResult.error) {
        console.log("Error inserting into profiles_tenants:", insertPTResult.error);
        return err(insertPTResult.error);
      }

      console.log("Inserted into profiles_tenants:", insertPTResult.data);

      // Update the profile with the new payload
      const updateProfileResult = await this.supabase
        .from("profiles")
        .update(body)
        .eq("id", existingProfile.id)
        .select()
        .single();

      if (updateProfileResult.error) {
        console.log("Error updating profile:", updateProfileResult.error);
        return err(updateProfileResult.error);
      }

      console.log("Profile updated:", updateProfileResult.data);
      return ok(updateProfileResult.data); // Return updated profile
    }
  }

  /** Tenant profiles */
  async getTenantProfiles<Table = Tables<"profiles_tenants">>({
    fields = "*",
    tenantId,
    query = "",
    count = "estimated",
    start,
    end,
  }: {
    fields?: string;
    tenantId: string;
    query?: string;
    categories?: string[];
    count?: "exact" | "planned" | "estimated";
    start?: number;
    end?: number;
  }): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("profiles_tenants")
      .select(fields, { count })
      .eq("tenant_id", tenantId)
      .is("profiles.deleted_at", null);

    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    // if (query.trim() !== "") {
    //   searchQuery.or(`profiles.full_name.ilike.%${query}%`);
    // }

    const searchResult = await searchQuery;
    if (searchResult.error) {
      return err(searchResult.error);
    }

    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  /** Addresses */
  async createAddress<Table = Tables<"addresses">>(
    body: TablesInsert<"addresses">,
    fields = "*"
  ): Promise<Result<Table, PostgrestError | null>> {
    const result = await this.supabase
      .from("addresses")
      .insert({ ...body })
      .select()
      .returns<Table>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }
  async getAddressById<Table = Tables<"addresses">>(id: number, tenantId: string) {
    const { data, error } = await this.supabase
      .from("addresses")
      .select("*")
      .eq("tenant_id", tenantId)
      .eq("id", id)
      .returns<Table[]>();
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  /** Checkouts */
  async createCheckout<Table = Tables<"checkouts">>(body: TablesInsert<"checkouts">, fields = "*") {
    const result = await this.supabase.from("checkouts").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }
    return ok(result.data[0]);
  }

  async updateCheckout<Table = Tables<"checkouts">>(body: TablesInsert<"checkouts">) {
    console.log("body", body);
    if (!body.id) return err(new Error("No checkout Id provided!"));
    const { data, error } = await this.supabase
      .from("checkouts")
      .update(body)
      .eq("tenant_id", body.tenant_id || "")
      .eq("id", body.id)
      .select("*")
      .returns<Table[]>();
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  async getProfileById<Table = Tables<"profiles">>({
    fields = "*",
    count = "estimated",
    id,
    tenantId = "",
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("profiles").select(fields, { count }).eq("tenant_id", tenantId);

    if (id) {
      query.eq("id", id);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async getCheckouts<Table = Tables<"checkouts">>({
    fields = "*",
    count = "estimated",
    id,
    uuid,
    tenantId = "",
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    uuid?: string;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("checkouts").select(fields, { count }).eq("tenant_id", tenantId);

    if (id) {
      query.eq("id", id);
    }

    if (uuid) {
      query.eq("uuid", uuid);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async getCheckoutById<Table = Tables<"checkouts">>({
    fields = "*",
    count = "estimated",
    id,
    tenantId = "",
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("checkouts").select(fields, { count }).eq("tenant_id", tenantId);

    if (id) {
      query.eq("id", id);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async updateCheckoutGateway(checkoutId: number, gatewayType: string) {
    if (!checkoutId || !gatewayType) return err(new Error("No checkout Id or gateway type provided!"));
    const { data, error } = await this.supabase
      .from("checkouts")
      .upsert({ id: checkoutId, payment_gateway_type: gatewayType })
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  async updateCheckoutOrderId(checkoutId: number, orderId: number) {
    if (!checkoutId || !orderId) return err(new Error("No checkout Id or gateway type provided!"));
    const { data, error } = await this.supabase
      .from("checkouts")
      .upsert({ id: checkoutId, order_id: orderId })
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  /** Discounts */

  async getDiscountCode(discountCode: string, tenantId: string) {
    const { data: discountData, error: discountError } = await this.supabase
      .from("discounts")
      .select("*")
      .eq("code", discountCode)
      .eq("tenant_id", tenantId)
      .single();
    if (discountError || !discountData) {
      return err(new Error("Discount code is invalid, inactive, or conditions are not met."));
    }
    return ok(discountData);
  }

  async applyDiscountCode<Table = Tables<"carts">>(
    cart: JoinedCart,
    discountCode: string,
    fields = "*",
    tenantId = ""
  ) {
    // Step 1: Check if the discount code meets all conditions (exists, active, not deleted and not expired)
    const { data: discountData, error: discountError } = await this.supabase
      .from("discounts")
      .select("*")
      .eq("code", discountCode)
      .eq("active", true)
      .eq("tenant_id", tenantId)
      .is("deleted_at", null)
      .lte("starts_at", new Date().toISOString())
      .gte("ends_at", new Date().toISOString())
      .single();

    if (discountError || !discountData) {
      return err(new Error("Discount code is invalid, inactive, or conditions are not met."));
    }

    // Step 2: Additional check for usage count against usage limit
    if (discountData.usage_count && discountData.usage_limit && discountData.usage_count >= discountData.usage_limit) {
      return err(new Error("Discount code has reached its usage limit."));
    }

    // TODO Step 3: We need to implement once_per_customer check when we have the customer authentication

    // Step 4: Calculate the cart discounted amount and total price
    const discountAmount = discountData.value;
    const isPercentage = discountData.value_type === DiscountValueType.percentage;
    const cartSubtotal = cart.subtotal_price; //  cart.subtotal_price is the total of the line_items before discount

    if (!cartSubtotal) return err(new Error("No cart subtotal!"));
    if (!discountAmount) return err(new Error("No discounted amount!"));

    // Calculate the discounted amount
    let cartDiscountedAmount = 0;
    if (isPercentage) {
      cartDiscountedAmount = (cartSubtotal * discountAmount) / 100;
    } else {
      cartDiscountedAmount = discountAmount;
    }

    // Step 5: Ensure the discounted amount doesn't exceed the subtotal
    cartDiscountedAmount = Math.min(cartDiscountedAmount, cartSubtotal);

    // Step 6: Calculate the total price after discount
    const cartTotalPrice = cartSubtotal - cartDiscountedAmount;

    // Step 7: Update the cart with the discount
    const updatedFields = {
      discount_id: discountData.id,
      discounted_amount: cartDiscountedAmount,
      total_price: cartTotalPrice,
    };

    const { data: cartData, error: cartError } = await this.supabase
      .from("carts")
      .update(updatedFields)
      .eq("id", cart.id)
      .eq("tenant_id", cart.tenant_id || "")
      .select(fields)
      .returns<Table[]>()
      .single();

    if (cartError || !cartData) {
      return err(cartError || new Error("Failed to update the cart with the discount code."));
    }

    return ok(cartData);
  }

  async getDiscounts<Table = Tables<"discounts">>({
    fields = "*",
    tenantId,
    query = "",
    count = "estimated",
    start,
    end,
  }: {
    fields?: string;
    tenantId: string;
    query?: string;
    categories?: string[];
    count?: "exact" | "planned" | "estimated";
    start?: number;
    end?: number;
  }): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("discounts")
      .select(fields, { count })
      .eq("tenant_id", tenantId)
      .is("deleted_at", null);
    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    if (query.trim() != "") {
      searchQuery.or(`code.ilike.%${query}%`);
    }
    const searchResult = await searchQuery;
    if (searchResult.error) {
      return err(searchResult.error);
    }
    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  async getDiscount<Table = Tables<"discounts">>(id: number, fields = "*"): Promise<Result<Table, PostgrestError>> {
    const result = await this.supabase.from("discounts").select(fields).eq("id", id).returns<Table[]>().single();

    if (result.error) {
      return err(result.error);
    }
    return ok(result.data);
  }

  /** Price modifiers */

  async getPriceModifiers<Table = Tables<"price_modifiers_assignments">>({
    fields = `*,
          price_modifiers (
            *,
            price_modifiers_components ( * )
          )`,
    count = "estimated",
    tenantId = "",
    query = "",
    start,
    end,
    type,
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    handle?: string;
    tenantId?: string;
    query?: string;
    start?: number;
    end?: number;
    type?: string;
  } = {}): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("price_modifiers_assignments")
      .select(fields, { count })
      .eq("tenant_id", tenantId)
      .is("deleted_at", null);

    if (type && type !== "all") {
      searchQuery.eq("type", type);
    }

    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    if (query.trim() != "") {
      searchQuery.or(`name.ilike.%${query}%`);
    }

    const searchResult = await searchQuery;

    if (searchResult.error) {
      return err(searchResult.error);
    }

    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  async createPriceModifier<Table = Tables<"price_modifiers">>(body: TablesInsert<"price_modifiers">, fields = "*") {
    const result = await this.supabase.from("price_modifiers").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      console.log("result", result);
      return err(result.error);
    }
    return ok(result.data[0]);
  }

  async createPriceModifierComponents<Table = Tables<"price_modifiers_components">[]>(
    body: TablesInsert<"price_modifiers_components">[],
    fields = "*"
  ) {
    const result = await this.supabase
      .from("price_modifiers_components")
      .insert(body)
      .select(fields)
      .returns<Table[]>();
    if (result.error) {
      return err(result.error);
    }
    return ok(result.data);
  }

  async createPriceModifierAssignments<Table = Tables<"price_modifiers_assignments">[]>(
    body: TablesInsert<"price_modifiers_assignments">[],
    fields = "*"
  ) {
    const result = await this.supabase
      .from("price_modifiers_assignments")
      .insert(body)
      .select(fields)
      .returns<Table[]>();
    if (result.error) {
      return err(result.error);
    }
    return ok(result.data);
  }

  async updatePriceModifier(
    priceModifierId: number,
    updatedFields: TablesInsert<"price_modifiers">
  ): Promise<Result<Tables<"price_modifiers">, PostgrestError>> {
    const { data, error } = await this.supabase
      .from("price_modifiers")
      .update(updatedFields)
      .eq("id", priceModifierId)
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  async updatePriceModifierComponent(
    componentId: number,
    updatedFields: TablesInsert<"price_modifiers_components">
  ): Promise<Result<Tables<"price_modifiers_components">, PostgrestError>> {
    const { data, error } = await this.supabase
      .from("price_modifiers_components")
      .update(updatedFields)
      .eq("id", componentId)
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  async updatePriceModifierAssignment(
    assignmentId: number,
    updatedFields: TablesInsert<"price_modifiers_assignments">
  ): Promise<Result<Tables<"price_modifiers_assignments">, PostgrestError>> {
    const { data, error } = await this.supabase
      .from("price_modifiers_assignments")
      .update(updatedFields)
      .eq("id", assignmentId)
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  /** Orders */
  async createOrder<Table = Tables<"orders">>(body: TablesInsert<"orders">, fields = "*") {
    const result = await this.supabase.from("orders").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }
    return ok(result.data[0]);
  }

  async getOrderByUuid<Table = Tables<"orders">>({
    fields = "*",
    count = "estimated",
    id,
    uuid,
    tenantId,
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    uuid?: string;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("orders").select(fields, { count });

    if (id) {
      query.eq("id", id);
    }

    if (uuid) {
      query.eq("uuid", uuid);
    }

    if (tenantId) {
      query.eq("tenant_id", tenantId);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data[0]);
  }

  async getOrderById<Table = Tables<"orders">>({
    fields = "*",
    count = "estimated",
    id,
    tenantId = "",
  }: {
    fields?: string;
    count?: "exact" | "planned" | "estimated";
    id?: number;
    tenantId?: string;
  } = {}) {
    let query = this.supabase.from("orders").select(fields, { count }).eq("tenant_id", tenantId);

    if (id) {
      query.eq("id", id);
    }

    const result = await query.returns<Table[]>();

    if (result.error) {
      return err(result.error);
    }

    return ok(result.data);
  }

  async getOrders<Table = Tables<"orders">>({
    tenantId,
    query = "",
    count = "estimated",
    start,
    end,
  }: {
    tenantId: string;
    query?: string;
    categories?: string[];
    count?: "exact" | "planned" | "estimated";
    start?: number;
    end?: number;
  }): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("orders")
      // .select("*", { count })
      .select(
        "*, checkouts!orders_checkout_id_fkey( * , addresses!checkouts_invoicing_address_id_fkey(*), carts( * ,line_items(*))), tenants(*)",
        { count }
      )
      .eq("tenant_id", tenantId)
      .eq("status", "paid")
      .or("production_status.is.null,production_status.eq.processing")
      .order("id", { ascending: false });

    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    const searchResult = await searchQuery;
    if (searchResult.error) {
      return err(searchResult.error);
    }
    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  async getOrdersStats<Table = Tables<"orders">>({
    tenantId,
  }: {
    tenantId: string;
  }): Promise<
    Result<
      {
        currentMonthCount: number;
        lastMonthCount: number;
        currentMonthTotal: number;
        lastMonthTotal: number;
        inProgressOrdersCount: number;
      },
      PostgrestError
    >
  > {
    const currentMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const currentMonthStart = currentMonthDate.toISOString();
    const lastMonthDate = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() - 1, 1);
    const lastMonthStart = lastMonthDate.toISOString();
    const lastMonthEndDate = new Date(currentMonthDate.getTime() - 1);
    const lastMonthEnd = lastMonthEndDate.toISOString();

    // Query 1: Orders count for this month
    const { count: currentMonthCount, error: currentMonthCountError } = await this.supabase
      .from("orders")
      .select("id", { count: "exact", head: true })
      .eq("tenant_id", tenantId)
      .eq("status", "paid")
      .gte("created_at", currentMonthStart);

    if (currentMonthCountError) {
      return err(currentMonthCountError);
    }

    // Query 2: Orders count of last month
    const { count: lastMonthCount, error: lastMonthCountError } = await this.supabase
      .from("orders")
      .select("id", { count: "exact", head: true })
      .eq("tenant_id", tenantId)
      .eq("status", "paid")
      .gte("created_at", lastMonthStart)
      .lte("created_at", lastMonthEnd);

    if (lastMonthCountError) {
      return err(lastMonthCountError);
    }

    // Query 3: Sum of orders.total_price for this month
    const { data: currentMonthTotalData, error: currentMonthTotalError } = await this.supabase
      .from("orders")
      .select("total_price")
      .eq("tenant_id", tenantId)
      .eq("status", "paid")
      .gte("created_at", currentMonthStart);

    if (currentMonthTotalError) {
      return err(currentMonthTotalError);
    }

    const currentMonthTotal = currentMonthTotalData?.reduce((sum, order) => sum + (order.total_price || 0), 0) ?? 0;

    // Query 4: Sum of orders.total_price for last month
    const { data: lastMonthTotalData, error: lastMonthTotalError } = await this.supabase
      .from("orders")
      .select("total_price")
      .eq("tenant_id", tenantId)
      .eq("status", "paid")
      .gte("created_at", lastMonthStart)
      .lte("created_at", lastMonthEnd);

    if (lastMonthTotalError) {
      return err(lastMonthTotalError);
    }

    const lastMonthTotal = lastMonthTotalData?.reduce((sum, order) => sum + (order.total_price || 0), 0) ?? 0;

    // Query 5: Orders count where status is not 'paid'
    const { count: inProgressOrdersCount, error: inProgressOrdersCountError } = await this.supabase
      .from("orders")
      .select("id", { count: "exact", head: true })
      .eq("tenant_id", tenantId)
      .or("production_status.is.null,production_status.eq.processing");

    if (inProgressOrdersCountError) {
      return err(inProgressOrdersCountError);
    }

    return ok({
      currentMonthCount: currentMonthCount ?? 0,
      lastMonthCount: lastMonthCount ?? 0,
      currentMonthTotal,
      lastMonthTotal,
      inProgressOrdersCount: inProgressOrdersCount ?? 0,
    });
  }

  // Customers
  async getCustomers<Table = Tables<"profiles">>({
    fields = "*, profiles_tenants!inner(tenant_id)",
    tenantId,
    query = "",
    count = "estimated",
    start,
    end,
  }: {
    fields?: string;
    tenantId: string;
    query?: string;
    categories?: string[];
    count?: "exact" | "planned" | "estimated";
    start?: number;
    end?: number;
  }): Promise<Result<[Table[], number], PostgrestError>> {
    const searchQuery = this.supabase
      .from("profiles")
      .select(fields, { count })
      .eq("profiles_tenants.tenant_id", tenantId)
      .not("orders", "is", null);

    if (isNumber(start) && isNumber(end)) {
      searchQuery.range(start, end - 1);
    }

    if (query.trim() != "") {
      searchQuery.or(`full_name.ilike.%${query}%,email.ilike.%${query}%,username.ilike.%${query}%`);
    }
    const searchResult = await searchQuery;
    if (searchResult.error) {
      return err(searchResult.error);
    }
    return ok([searchResult.data as Table[], searchResult.count as number]);
  }

  async getCustomer<Table = Tables<"profiles">>(id: number, fields = "*"): Promise<Result<Table, PostgrestError>> {
    const result = await this.supabase.from("profiles").select(fields).eq("id", id).returns<Table[]>().single();

    if (result.error) {
      return err(result.error);
    }
    return ok(result.data);
  }

  // Discounts

  async createDiscount<Table = Tables<"discounts">>(body: TablesInsert<"discounts">, fields = "*") {
    const result = await this.supabase.from("discounts").insert(body).select(fields).returns<Table[]>();

    if (result.error) {
      console.log("result", result);
      return err(result.error);
    }
    return ok(result.data[0]);
  }
  async updateDiscount(
    discountId: number,
    updatedFields: TablesInsert<"discounts">
  ): Promise<Result<Tables<"discounts">, PostgrestError>> {
    const { data, error } = await this.supabase
      .from("discounts")
      .update(updatedFields)
      .eq("id", discountId)
      .select("*");
    if (error) {
      return err(error);
    }
    return ok(data[0]);
  }

  getImageUrl(bucket: string, imageName: string) {
    if (imageName.startsWith("http")) return imageName;

    return this.supabase.storage.from(bucket).getPublicUrl(imageName).data.publicUrl;
  }
}
