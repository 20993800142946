"use client";
import { find } from "lodash";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { useStoreState, useStoreActions } from "@/hooks/storeHooks";
import { AccountSwitcher } from "./account-switcher";
import { Building } from "lucide-react";
import { Tables } from "@/types";

export interface IPaymentStatus {
  label: string;
  value: "paid" | "draft"; //"unpaid" | "paid" | "deposit_paid";
}
export const PaymentStatuses = [
  { label: "Unpaid", value: "draft", color: "red-500" },
  { label: "Paid", value: "paid", color: "green-500" },
  //{ label: "Deposit Paid", value: "deposit_paid", color: "yellow-500" },
];

export const PurchasingStatuses = [
  {
    label: "Purchased",
    value: "purchased",
  },
  {
    label: "Shipped",
    value: "shipped",
    className: "bg-green-200 border-green-500 text-green-700",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
  {
    label: "Canceled",
    value: "canceled",
  },
  {
    label: "Returned",
    value: "returned",
  },
];
/*{
    label: "Being verified",
    value: "paid|improved",
  }*/
export type ProductionStatusValue = null | "processing" | "done" | "paid" | "draft";
export const ProductionStatuses: { label: string; value: ProductionStatusValue }[] = [
  {
    label: "New Order",
    value: null,
  },
  {
    label: "Processing",
    value: "processing",
  },
  {
    label: "Done",
    value: "done",
  },
];

interface DropdownProps {
  className?: string;
  value?: string[];
  onChange?: (newValues: string[]) => void;
}

export function StoreOrderStatusDropdown(props: DropdownProps) {
  return <DataTableFacetedFilter title="Status" maxSelected={1} options={ProductionStatuses} {...props} />;
}

export function CustomerOrderStatusDropdown(props: DropdownProps) {
  return (
    <DataTableFacetedFilter
      title="Status"
      maxSelected={1}
      options={[
        {
          label: "My Label",
          value: "my_value",
        },
        {
          label: "My Other Value",
          value: "my_value_2",
        },
      ]}
      {...props}
    />
  );
}

export function PurchasingStatusDropdown(props: DropdownProps) {
  return (
    <DataTableFacetedFilter
      title="Status"
      maxSelected={1}
      options={PurchasingStatuses.map((m) => {
        return { label: m.label, value: m.value };
      })}
      {...props}
    />
  );
}

export function TenantsDropdown(props: DropdownProps) {
  const tenant = useStoreState((state) => state.adminTenant);
  const setTenant = useStoreActions((actions) => actions.setAdminTenant);
  const profile = useStoreState((s) => s.profile);

  if (!profile) return <></>;

  return (
    <AccountSwitcher
      isCollapsed={false}
      accounts={profile.profiles_tenants.map((tenant) => tenant.tenants) as Tables<"tenants">[]}
      value={tenant?.tenants?.id}
      onChange={(newValue) => {
        if (!newValue) {
          setTenant(null);
          return;
        }

        const tenantObject = find(profile.profiles_tenants, (tenant) => tenant?.tenants?.id === newValue);

        if (tenantObject && tenantObject.tenants) {
          setTenant(tenantObject);
          localStorage.setItem("lastAdminTenant", tenantObject.tenants.id);
        } else {
          setTenant(null);
        }
      }}
    />
  );
}
