"use client";

import { Fragment, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import { Bars3Icon, Cog6ToothIcon, HomeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Badge } from "src/components/ui/badge";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@/components/ui/collapsible";
import { SidebarGroup, SidebarGroupLabel, SidebarGroupContent } from "@/components/ui/sidebar";
import { cn } from "@/lib/utils/stylesUtils";
import {
  ShoppingBag,
  CircleDollarSign,
  Percent,
  Shirt,
  UsersRound,
  HandCoins,
  Link as LucideLink,
  CreditCard,
  Scale,
  FileText,
  Bell,
  UserRoundCog,
  ChevronDown,
} from "lucide-react";
import { TenantsDropdown } from "@/components/ui/all-dropdowns";
import { createClient } from "@/lib/supabase/client";
import { getUserAvatar } from "../../../helpers/getTenantLogoUrl";
import { MyAvatar } from "../../../components/ui/MyAvatar";
import { hasPermission } from "../../../components/Restricted";

const isCurrent = (itemPath: string, currentPath: string | null): boolean => {
  if (!currentPath) return false;

  // Handle root path special case
  if (itemPath === "/" && currentPath === "/") return true;
  if (itemPath === "/") return false;

  // Check if current path starts with the item path
  return currentPath.startsWith(itemPath);
};

export default function AdminStoreSidebar({
  children,
  ordersCount,
}: {
  children: React.ReactNode;
  ordersCount: number;
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const pathname = usePathname();
  const profile = useStoreState((state) => state.profile);
  console.log("PROFILE", profile);
  const selectedTenant = useStoreState((state) => state.adminTenant);
  const setProfile = useStoreActions((actions) => actions.setProfile);

  const totalOrdersCount = ordersCount || null;

  const navigation = (function getNavigation() {
    if (!selectedTenant) return [];

    const financeRoles = [
      "payment_links.view",
      "payments.view",
      "payouts.view",
      "notifications.view",
      "balances.view",
      "documents.view",
      "account_management.view",
    ];
    const hasFinanceRole =
      selectedTenant.tenants?.stripe_account_id && financeRoles.some((role) => hasPermission(role, selectedTenant));

    return [
      {
        name: "Dashboard",
        href: "/",
        icon: HomeIcon,
        current: true,
        requiredRole: "dashboard.view",
      },
      {
        name: "Orders",
        href: "/orders",
        icon: ShoppingBag,
        current: isCurrent("/orders", pathname),
        outstanding: totalOrdersCount,
        requiredRole: "orders.view",
      },
      {
        name: "Customers",
        href: "/customers",
        icon: UsersRound,
        current: false,
        requiredRole: "customers.view",
      },
      {
        name: "Products",
        href: "/products",
        icon: Shirt,
        current: false,
        requiredRole: "products.view",
      },
      {
        name: "Discounts",
        href: "/discounts",
        icon: Percent,
        current: false,
        requiredRole: "discounts.view",
      },
      {
        name: "Payment Links",
        href: "/links",
        icon: LucideLink,
        current: false,
        requiredRole: "payment_links.view",
      },
      hasFinanceRole
        ? {
            name: "Finance",
            icon: CircleDollarSign,
            children: [
              {
                name: "Payments",
                href: "/finance/payments",
                icon: CreditCard,
                current: false,
                requiredRole: "payments.view",
              },
              {
                name: "Payouts",
                href: "/finance/payouts",
                icon: HandCoins,
                current: false,
                requiredRole: "payouts.view",
              },
              {
                name: "Notifications",
                href: "/finance/notifications",
                icon: Bell,
                current: false,
                requiredRole: "notifications.view",
              },
              {
                name: "Balances",
                href: "/finance/balances",
                icon: Scale,
                current: false,
                requiredRole: "balances.view",
              },
              {
                name: "Documents",
                href: "/finance/documents",
                icon: FileText,
                current: false,
                requiredRole: "documents.view",
              },
              {
                name: "Account Management",
                href: "/finance/account-management",
                icon: UserRoundCog,
                current: false,
                requiredRole: "account_management.view",
              },
            ].filter((navItem) => !navItem.requiredRole || hasPermission(navItem.requiredRole, selectedTenant)),

            // icon: LucideLink,
            current: false,
            requiredRole: "account_management.view",
          }
        : {},
      // ...(selectedTenant.tenants?.stripe_account_id
      //   ? [
      //       {
      //         name: "Payments",
      //         href: "/finance/payments",
      //         icon: CreditCard,
      //         current: false,
      //         requiredRole: "payments.view",
      //       },
      //       {
      //         name: "Payouts",
      //         href: "/finance/payouts",
      //         icon: HandCoins,
      //         current: false,
      //         requiredRole: "payouts.view",
      //       },
      //       {
      //         name: "Notifications",
      //         href: "/finance/notifications",
      //         icon: Bell,
      //         current: false,
      //         requiredRole: "notifications.view",
      //       },
      //       {
      //         name: "Balances",
      //         href: "/finance/balances",
      //         icon: Scale,
      //         current: false,
      //         requiredRole: "balances.view",
      //       },
      //       {
      //         name: "Documents",
      //         href: "/finance/documents",
      //         icon: FileText,
      //         current: false,
      //         requiredRole: "documents.view",
      //       },
      //       {
      //         name: "Account Management",
      //         href: "/finance/account-management",
      //         icon: UserRoundCog,
      //         current: false,
      //         requiredRole: "account_management.view",
      //       },
      //     ]
      //   : []),
    ].filter((navItem) => !navItem.requiredRole || hasPermission(navItem.requiredRole, selectedTenant));
  })();

  const userNavigation = [
    { name: "Your profile", href: "/settings/account" },
    {
      name: "Sign out",
      onClick: () => {
        const supabase = createClient();
        supabase.auth.signOut();
        setProfile(null);
      },
    },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <Link href={"/"}>
                    <img alt="Flexchain Icon" src={"/flexchain-logo.png"} className="h-8 w-auto" />
                  </Link>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <Fragment key={item.name}>
                            {item.children && item.children.length > 0 ? (
                              // <li key={item.name}>{item.name}</li>

                              <Collapsible defaultOpen className="group/collapsible">
                                <SidebarGroup className="  m-0 p-0">
                                  <SidebarGroupLabel asChild className="">
                                    <CollapsibleTrigger className="">
                                      <span className="text-gray-700 hover:bg-gray-50 hover:text-neil-600 group flex gap-x-3 rounded-md text-sm font-semibold leading-6">
                                        {" "}
                                        <item.icon
                                          aria-hidden="true"
                                          className={cn(
                                            "text-gray-400 group-hover:text-neil-600 h-6 w-6 shrink-0"
                                            // isCurrent(child.href, pathname)
                                            //   ? "text-neil-600"
                                            //   :
                                            //   "text-gray-400 group-hover:text-neil-600",
                                            // "h-6 w-6 shrink-0"
                                          )}
                                        />
                                        {item.name}
                                      </span>
                                      <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                    </CollapsibleTrigger>
                                  </SidebarGroupLabel>
                                  <CollapsibleContent>
                                    <ul>
                                      {item.children.map((child) => {
                                        return (
                                          <li key={child.name}>
                                            <Link
                                              href={child.href}
                                              className={cn(
                                                isCurrent(child.href, pathname)
                                                  ? "bg-gray-50 text-neil-600"
                                                  : "text-gray-700 hover:bg-gray-50 hover:text-neil-600",
                                                "group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6"
                                              )}
                                            >
                                              <child.icon
                                                aria-hidden="true"
                                                className={cn(
                                                  isCurrent(child.href, pathname)
                                                    ? "text-neil-600"
                                                    : "text-gray-400 group-hover:text-neil-600",
                                                  "h-6 w-6 shrink-0"
                                                )}
                                              />
                                              <span>{child.name}</span>
                                              <div className="grow"></div>
                                              {/* {child.outstanding && (
                                      <Badge variant={"default"} className="bg-neil hover:bg-neil-400">
                                        {child.outstanding}
                                      </Badge>
                                    )} */}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                    {/* <SidebarGroupContent /> */}
                                  </CollapsibleContent>
                                </SidebarGroup>
                              </Collapsible>
                            ) : item.href ? (
                              <li key={item.name}>
                                <Link
                                  href={item.href}
                                  className={cn(
                                    isCurrent(item.href, pathname)
                                      ? "bg-gray-50 text-neil-600"
                                      : "text-gray-700 hover:bg-gray-50 hover:text-neil-600",
                                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                  )}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className={cn(
                                      isCurrent(item.href, pathname)
                                        ? "text-neil-600"
                                        : "text-gray-400 group-hover:text-neil-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                  />
                                  <span>{item.name}</span>
                                  <div className="grow"></div>
                                  {item.outstanding && (
                                    <Badge variant={"default"} className="bg-neil hover:bg-neil-400">
                                      {item.outstanding}
                                    </Badge>
                                  )}
                                </Link>
                              </li>
                            ) : (
                              <li key={item.name}>{item.name}</li>
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    </li>

                    <li className="mt-auto">
                      <Link
                        href="/settings/account"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-neil-600"
                      >
                        <Cog6ToothIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-neil-600"
                        />
                        Settings
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <Link href={"/"}>
                <img alt="Flexchain Icon" src="/flexchain-logo.png" className="h-8 w-auto" />
              </Link>
            </div>

            <div className="flex flex-row space-x-1">
              <TenantsDropdown />

              {/*selectedTenant && selectedTenant.studio_domain && (
                <Link href={"https://" + selectedTenant.studio_domain} target="_blank">
                  <Button variant={"outline"}>
                    <ExternalLink className="h-4 w-4" />
                  </Button>
                </Link>
              )*/}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <Fragment key={item.name}>
                        {item.children && item.children.length > 0 ? (
                          // <li key={item.name}>{item.name}</li>

                          <Collapsible defaultOpen className="group/collapsible">
                            <SidebarGroup className="  m-0 p-0">
                              <SidebarGroupLabel asChild className="">
                                <CollapsibleTrigger className="">
                                  <span className="text-gray-700 hover:bg-gray-50 hover:text-neil-600 group flex gap-x-3 rounded-md text-sm font-semibold leading-6">
                                    {" "}
                                    <item.icon
                                      aria-hidden="true"
                                      className={cn(
                                        "text-gray-400 group-hover:text-neil-600 h-6 w-6 shrink-0"
                                        // isCurrent(child.href, pathname)
                                        //   ? "text-neil-600"
                                        //   :
                                        //   "text-gray-400 group-hover:text-neil-600",
                                        // "h-6 w-6 shrink-0"
                                      )}
                                    />
                                    {item.name}
                                  </span>
                                  <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                </CollapsibleTrigger>
                              </SidebarGroupLabel>
                              <CollapsibleContent>
                                <ul>
                                  {item.children.map((child) => {
                                    return (
                                      <li key={child.name}>
                                        <Link
                                          href={child.href}
                                          className={cn(
                                            isCurrent(child.href, pathname)
                                              ? "bg-gray-50 text-neil-600"
                                              : "text-gray-700 hover:bg-gray-50 hover:text-neil-600",
                                            "group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6"
                                          )}
                                        >
                                          <child.icon
                                            aria-hidden="true"
                                            className={cn(
                                              isCurrent(child.href, pathname)
                                                ? "text-neil-600"
                                                : "text-gray-400 group-hover:text-neil-600",
                                              "h-6 w-6 shrink-0"
                                            )}
                                          />
                                          <span>{child.name}</span>
                                          <div className="grow"></div>
                                          {/* {child.outstanding && (
                                <Badge variant={"default"} className="bg-neil hover:bg-neil-400">
                                  {child.outstanding}
                                </Badge>
                              )} */}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                                {/* <SidebarGroupContent /> */}
                              </CollapsibleContent>
                            </SidebarGroup>
                          </Collapsible>
                        ) : item.href ? (
                          <li key={item.name}>
                            <Link
                              href={item.href}
                              className={cn(
                                isCurrent(item.href, pathname)
                                  ? "bg-gray-50 text-neil-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-neil-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={cn(
                                  isCurrent(item.href, pathname)
                                    ? "text-neil-600"
                                    : "text-gray-400 group-hover:text-neil-600",
                                  "h-6 w-6 shrink-0"
                                )}
                              />
                              <span>{item.name}</span>
                              <div className="grow"></div>
                              {item.outstanding && (
                                <Badge variant={"default"} className="bg-neil hover:bg-neil-400">
                                  {item.outstanding}
                                </Badge>
                              )}
                            </Link>
                          </li>
                        ) : (
                          <li key={item.name}>{item.name}</li>
                        )}
                      </Fragment>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <Link
                    href="/settings/account"
                    className={cn(
                      isCurrent("/settings", pathname)
                        ? "bg-gray-50 text-neil-600"
                        : "text-gray-700 hover:bg-gray-50 hover:text-neil-600",
                      "group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                    )}
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className={cn(
                        isCurrent("/settings", pathname) ? "text-neil-600" : "text-gray-400 group-hover:text-neil-600",
                        "h-6 w-6 shrink-0"
                      )}
                    />
                    Settings
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              {/*<form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                />
                <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Search..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm"
                />
              </form>*/}
              <div className="relaive flex flex-1">{/* remove me when you add search form again */}</div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/*<button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                
                <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                />*/}

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <MyAvatar
                      src={getUserAvatar("" + profile?.uuid, "" + profile?.avatar_url)}
                      fallbackText={profile?.full_name || "?"}
                    />

                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                        {profile?.full_name}
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        <Link
                          href={item.href || "#"}
                          onClick={item.onClick ? item.onClick : () => {}}
                          className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          {children}
        </div>
      </div>
    </>
  );
}
