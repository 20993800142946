"use client";

import * as React from "react";
import { cn } from "../../lib/utils/stylesUtils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { Tables } from "../../types";
import { getTenantFaviconUrl } from "../../helpers/getTenantLogoUrl";

interface AccountSwitcherProps {
  isCollapsed: boolean;
  accounts: Tables<"tenants">[];
  value?: string;
  onChange: (value: string) => void;
}

export function AccountSwitcher({ isCollapsed, accounts, value, onChange }: AccountSwitcherProps) {
  let thisAccount = accounts.find((account) => account.id === value);

  if (!thisAccount) return <>No Shop</>;
  return (
    <Select
      defaultValue={value}
      value={value}
      onValueChange={(newValue) => {
        onChange(newValue);
      }}
    >
      <SelectTrigger
        className={cn(
          "flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
          isCollapsed && "flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden"
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select an account">
          {thisAccount.favicon_url && <img src={getTenantFaviconUrl(thisAccount)} className="h-4 w-4 rounded-xs" />}

          <span className={cn("ml-2", isCollapsed && "hidden")}>
            {accounts.find((account) => account.id === value)?.company_name}
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {accounts.map((account) => (
          <SelectItem key={account.id} value={account.id}>
            <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              {account.favicon_url && <img src={getTenantFaviconUrl(account)} className="h-4 w-4" />}
              {account.company_name}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
