import * as React from "react";
import { useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import SpinIcon from "../icon/SpinIcon";
import { getAvatarInitials } from "@/helpers/getAvatarInitials";

interface MyAvatarProps {
  src?: string;
  fallbackText?: string;
  className?: string;
}

export function MyAvatar({ src, fallbackText = "?", className }: MyAvatarProps) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Avatar className={className}>
      {src && <AvatarImage src={src} onLoadingStatusChange={(status) => setIsLoading(status === "loading")} />}
      <AvatarFallback>
        {isLoading && src ? (
          <SpinIcon className="text-[hsl(var(--primary))] h-4 w-4" loading={true} />
        ) : (
          getAvatarInitials(fallbackText)
        )}
      </AvatarFallback>
    </Avatar>
  );
}
