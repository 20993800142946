export function getAvatarInitials(fullName: string) {
  const splitted = fullName.split(" ");

  // "Pablo"
  if (splitted.length == 1) {
    return splitted[0].substring(0, 2).toUpperCase();
  }

  // "Pablo X Albrecht"
  else {
    return splitted[0].substring(0, 1).toUpperCase() + splitted[splitted.length - 1].substring(0, 1).toUpperCase();
  }
}
