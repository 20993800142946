import { useEffect, useState } from "react";
import useNeverthrowAsync from "./useNeverthrowAsync";
import { Tables } from "@/types";
import consts from "@/config/consts";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import { useStoreActions, useStoreState } from "./storeHooks";
import { NewJoinedOrder } from "@/types/supabase-custom/JoinedOrder";

const supabaseUtils = new SupabaseUtils(createClient());

interface Config {
  page?: number;
  limit?: number;
  search?: string;
  count?: "exact" | "planned" | "estimated";
  adminTenantId?: string;
  filters?: Array<
    | {
        status: string;
        production_status: string | null;
      }
    | undefined
  >;
}

export default function useOrders<Table extends NewJoinedOrder = NewJoinedOrder>({
  page = 1,
  limit = consts.ORDERS_PER_PAGE,
  search,
  count = "estimated",
  adminTenantId,
  filters = [],
}: Config = {}) {
  const [orders, setOrders] = useState<Table[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const tenantId = useStoreState((state) => state.tenant?.id);
  const setTenantOrders = useStoreActions((state) => state.setTenantOrders);
  const setTenantOrdersCount = useStoreActions((state) => state.setTenantOrdersCount);
  const [getOrders, loading] = useNeverthrowAsync(
    async function getOrders(tenantId: string) {
      const result = await supabaseUtils.getOrders<Table>({
        tenantId,
        query: search,
        start: (page - 1) * limit,
        end: page * limit,
        count,
      });

      if (result.isOk()) {
        const [orders, orderCount] = result.value;
        setOrders(orders);
        setOrdersCount(orderCount);
        setTenantOrders(orders);
        setTenantOrdersCount(orderCount);
      }
      console.log("result", result);
      return result;
    },
    { uiSuccessMessage: "", uiErrorMessage: "Failed to load orders", immediate: true }
  );

  useEffect(
    function getAndSetCustomers() {
      if (adminTenantId) {
        getOrders(adminTenantId);
      } else if (tenantId) {
        getOrders(tenantId);
      }
    },
    [page, search, tenantId, adminTenantId]
  );

  return { loading, orders, ordersCount };
}
