import { DiscountDetails } from "../discount/DiscountDetails";
import { KoalaImage } from "./KoalaImage";
import { KoalaSelectedOption } from "./KoalaVariant";

export enum DiscountType {
  'discount_code' = 'discount_code',
  'referral_code' = 'referral_code',
  'credits' = 'credits',
  'bundle' = 'bundle',
  'gift_card' = 'gift_card'
}

export enum DiscountValueType {
  'fixed_amount' = 'fixed_amount',
  'percentage' = 'percentage'
}

export interface Discount {
  type: DiscountType;
  name?: string;
  value: number;
  valueType?: 'fixed_amount' | 'percentage';
}


export interface KoalaAttribute {
  name: string;
  value: string;
}

export interface KoalaSelectedQuantity {
  size: string;
  quantity: number;
}

export interface GuidelinesOn {
  front: boolean; 
  back?: boolean; 
}
export interface ArtworkImage {
  front: KoalaImage; 
  back?: KoalaImage; 
}

export interface SizeAndPosition {
  width: number;
  fromNeckSeam: number;
  fromCenter: number;
  unit: string;
}

export interface ArtworkMetadata {
  smallestSize: string;
  printType: string;
  guidelinesOn: GuidelinesOn;
  leftChest?: boolean;
  image?: ArtworkImage;
  sizeAndPosition: SizeAndPosition;
}

export interface CustomProperty {
  color: string;
  artworkMetadata?: ArtworkMetadata 
}

export interface KoalaOption {
  name: string;
  values: string[];
}
export interface PricingList {
  threshold: number;
  price: number
}


export interface PriceList {
  _id: string;
  name?: string;
  customerId?: string;
  createdAt?: Date;
  deletedAt?:  Date;
  currency?: string;
  pricingList: PricingList[];
}



export interface KoalaCustomLineItem {
  _id: string;
  name: string;
  handle?: string;
  sku: string;
  discount?: Discount;
  price?: number;
  currency?: string;
  options?: KoalaOption[];
  defaultPriceListId?: PriceList;
  attributes: KoalaAttribute[];
  images: KoalaImage[];
  productId: string;
  selectedQuantities: KoalaSelectedQuantity[];
  addedToCart: boolean;
  customProperties: CustomProperty[];
}

export interface KoalaCart {
 /**
     * MongoDB ID of the Cart
     */
    _id: string;

    /**
     * total price of the Cart line items
     */
    totalPrice: number;

    /**
     * The currency used in the Cart
     */
    currency: string;

    /**
     * The line items of the Cart
     */
    lineItems?: KoalaLineItem[];


     /**
     * The line items of the Cart
     */
    customLineItems?: KoalaCustomLineItem[];
    

    /**
     * The discount applied in the Cart
     */
    discounts: Discount[];

    /**
     * The attributes in the Cart
     */
    attributes: KoalaAttribute[];

    /**
     * e.g. EG, MA
     */
    countryCode: string;

    /**
     * e.g. en-EG, ar-MA
     */
    deployment: string;

    /**
     * Once the cart is converted to a checkout, checkoutId gets assigned
     */
    checkoutId: string | null;

    isDeleted: boolean;

    shippingFees: ShippingFees | null;
}

export interface KoalaInsertCart {
  customLineItems: KoalaInsertCustomLineItem[];
  currency?: string;
  countryCode?: string;
  deployment?: string;
  discounts?: DiscountDetails[];
  attributes?: KoalaAttribute[];
}

export interface KoalaLineItem {
  _id: string;
  variantId: string;
  selectedOptions: KoalaSelectedOption[];
  name: string;
  quantity: number;
  price: number;
  currency: string;
  sku: string;
  images: KoalaImage[];
  discount?: DiscountDetails;
  attributes: KoalaAttribute[];
}

export interface KoalaInsertCustomLineItem {
  productId: string;
  name?: string;
  price?: number;
  currency?: string;
  attributes?: KoalaAttribute[];
  selectedQuantities?: KoalaSelectedQuantity[];
  addedToCart?: boolean;
  customProperties?: CustomProperty[];
}

export interface KoalaInsertLineItem {
  variantId: string;
  quantity: number;
  sku: string;
  discount?: DiscountDetails;
  attributes?: KoalaAttribute[];
}

export interface KoalaAttribute {
  name: string;
  value: string;
}

export interface ShippingFees {
  amount: number;
  type: string;
}
