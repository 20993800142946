import { FunctionComponent } from "react";
import SpinIcon from "../icon/SpinIcon";

export const LoadingLabel: FunctionComponent<any> = ({
  loading = true,
  message,
  className,
}: {
  loading?: boolean;
  message?: string;
  className?: any;
}) => {
  return (
    <>
      {loading && (
        <div className={className}>
          <div className=" text-slate-500 flex flex-row space-x-2 items-center">
            <SpinIcon className="text-[hsl(var(--primary))]" loading={true} />
            {message ? (
              <span className="my-auto text-sm text-muted-foreground">
                {message}
              </span>
            ) : (
              <span className="my-auto">Loading...</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
